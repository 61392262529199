import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const SecondPage = () => (
  <Layout>
    <SEO
      title="Colorado Architectural Designs"
      description="JKA Design is a multi-disciplinary architectural firm serving Colorado (Boulder, Denver, and Westminster). They handle house designs, remodels, and additions."
    />
    <div class="page-headline">
      <div class="container">
        <div class="section-heading text-center">
          <h6 class="font-weight-bold text-uppercase text-white-50 flair">
            Services
          </h6>
          <h1>
            <strong>
              Home Design and General Contractor Services in Colorado - JKA
              Design
            </strong>
          </h1>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="container">
        <div class="row justify-content-between align-items-center">
          <div class="col-md-12 mb-4">
            <div class="row justify-content-around py-5">
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/services/additions-remodels-and-renovations">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/ceaf75d590ccff4953740cd1997f90072b39933f-1030x818.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Additions Remodels & Renovations</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/services/custom-and-luxury-residential-design">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/f581efa2512c98543e446f5ed5c9b0a538ce69cf-800x600.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Custom & Luxury Residential Design</h4>
                    </div>
                  </Link>
                </div>
              </div>
              <div class="col-md-4 mb-5">
                <div class="h-100 services-inner shadow-sm">
                  <Link to="/services/other-architectural-services">
                    <div
                      class="services-image"
                      style={{
                        backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/a448226ca8ea3e87201b9767ee7b8c6d67bced94-818x1030.jpg"
												)`,
                      }}
                    />
                    <div class="services-content py-3 px-4">
                      <h4>Additional Architectural Services</h4>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <h4>
              Jeffry K. Abrams, Architect is a full-service architectural firm
              offering the following services:
            </h4>
            <div class="row justify-content-around py-5">
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Planning &amp; Zoning Consultation and Advising</li>
                  <li>Code research</li>
                  <li>Site Planning</li>
                  <li>Architectural Design</li>
                  <li>Commercial Building Design</li>
                  <li>Light Commercial Architectural Design</li>
                  <li>Multifamily Design</li>
                  <li>Preliminary Design</li>
                  <li>Schematic Design</li>
                  <li>Custom and Luxury Residential Design</li>
                  <li>Residential Addition – Design and consultation</li>
                  <li>Remodeling and Renovation Design</li>
                </ul>
              </div>
              <div class="col-md-6">
                <ul className="services-list">
                  <li>Construction Documents</li>
                  <li>Working Drawings</li>
                  <li>Construction Detailing</li>
                  <li>As Built Measuring and Drawings</li>
                  <li>Energy Consulting</li>
                  <li>Passive Solar Consultation and Design</li>
                  <li>Bidding or Negotiation</li>
                  <li>Construction Contract Consultancy</li>
                  <li>Site Observation and Advising</li>
                  <li>Design Review Consultation</li>
                  <li>Forensics</li>
                </ul>
              </div>
            </div>
            <h3 className="text-blue">
              IT ALL STARTS WITH A PLAN. . . OR DOES IT?
            </h3>
            <div class="row justify-content-around py-5">
              <div class="col-md-6 p-4 text-center">
                <h4 className="text-blue">1</h4>
                <p>
                  The process of creating your dream has many steps and, often,
                  twists and turns. First you must define the parameters of the
                  entire project and communicate your “program” to the person
                  (architect, designer, builder, interior designer, landscape
                  designer etc.) who will assist and guide you through this
                  process to get you started on the path of least
                  resistance–best design, most affordable build, building permit
                  and occupancy. Once there is a program of your wants and needs
                  communicated, you can begin the design process. Then it
                  “starts with a plan”.
                </p>
              </div>
              <div class="col-md-6 p-4 text-center">
                <h4 className="text-blue">2</h4>
                <p>
                  Our process of professional design service is client inclusive
                  and pocketbook sensitive. We want to bring the
                  builder/contractor in early to help organize the budgets and
                  construction approach. We will meet with you and clarify your
                  ideas and move the design from concept sketches to developed
                  design possibly including 3D drawings to help you visualize
                  the finished building.
                </p>
              </div>
              <div class="col-md-6 p-4 text-center">
                <h4 className="text-blue">3</h4>
                <p>
                  The next step is translation of the concepts and preliminary
                  design into computer generated, defined and dimensioned
                  drawings with exterior elevations as well as plans. Finally
                  the Construction Documents are created, again in CAD (computer
                  drawing) and including more detail, products, and specified
                  materials. We will provide drawings for your review and
                  discussion with builder along the way. Throughout the process,
                  local regulations and building codes are our constant
                  companions.
                </p>
              </div>
              <div class="col-md-6 p-4 text-center">
                <h4 className="text-blue">4</h4>
                <p>
                  When the documents are approved and with your authorization,
                  they will be provided to the contractor(s) for bidding by them
                  and their subcontractors. Bids and prices will be tendered
                  shortly thereafter, a builder chosen, and a contract
                  negotiated. At that point construction can begin; and
                  depending on your arrangement with your design professional,
                  they will provide oversight during the building phase
                </p>
              </div>
            </div>
            <div class="row justify-content-around py-5">
              <div class="col-md-6 mb-4">
                <h3 className="mb-4">The Value of a Licensed Architect</h3>
                <iframe
                  src="https://www.youtube.com/embed/FbR4_V1hzpg?rel=0&amp;showinfo=0&amp;wmode=opaque"
                  width="100%"
                  height="315"
                  frameborder="0"
                  title="The Value of a Licensed Architect"
                  allowfullscreen="allowfullscreen"
                ></iframe>
              </div>
              <div class="col-md-6 mb-4">
                <h3 className="mb-4">What Architects Do</h3>
                <iframe
                  src="https://www.youtube.com/embed/HTudH-4BysA?rel=0&amp;showinfo=0&amp;wmode=opaque"
                  width="100%"
                  height="315"
                  title="What Architects Do"
                  frameborder="0"
                  allowfullscreen="allowfullscreen"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="review-snippet padding-50 text-center text-white">
        <div className="container">
          <p className="text-white">
            <i className="fa fa-quote-left mr-3" />
            <em>
              “Jeff is a talented professional with an Ivy League degree that
              supports his uncanny designs. Jeff listens and translates the
              clients’ dreams into reality. I have seen many JKA houses and am
              always impressed with how they fit the landscape and actually
              enhance the setting. I highly recommend JKA for both new
              construction and also for any redesigns that will increase home
              values with Jeff’s insightful designs.”
            </em>
            <i className="fa fa-quote-right ml-3" />
          </p>
        </div>
      </div>
      <div className="some-content text-center">
        <h3 className="mb-5">Some select JKA projects</h3>
        <div className="container">
          <div class="row justify-content-around">
            <div class="col-md-4 mb-5">
              <div class="h-100 services-inner shadow-sm">
                <Link to="/portfolio-item/custom-euro-style-home">
                  <div
                    class="services-image"
                    style={{
                      backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/083d099318bfd68922abd10b296f370038016d4e-850x638.jpg"
												)`,
                    }}
                  />
                  <div class="services-content py-3 px-4">
                    <h4>Custom Euro-Style Home</h4>
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-md-4 mb-5">
              <div class="h-100 services-inner shadow-sm">
                <Link to="/portfolio-item/sketches-rendereings-and-models">
                  <div
                    class="services-image"
                    style={{
                      backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/9ed9f6ed728c3dc0d34ad27070e4e63c9736f419-1030x616.jpg"
												)`,
                    }}
                  />
                  <div class="services-content py-3 px-4">
                    <h4>Sketches, Rendereings and Models</h4>
                  </div>
                </Link>
              </div>
            </div>
            <div class="col-md-4 mb-5">
              <div class="h-100 services-inner shadow-sm">
                <Link to="/portfolio-item/tuscan-estate">
                  <div
                    class="services-image"
                    style={{
                      backgroundImage: `url(
													"https://cdn.sanity.io/images/2gff8qxw/production/11b282a7f4ec1101616d39ebb7480f1d548f7581-800x600.jpg"
												)`,
                    }}
                  />
                  <div class="services-content py-3 px-4">
                    <h4>Tuscan Estate</h4>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SecondPage
